import axios from "../../utils/axios";

//
export function groupClassifyList(params) {
    return axios({
        url: "/admin/groupClassify/list",
        method: "get",
        params: params
    });
}

//
export function groupClassifyAll() {
    return axios({
        url: "/admin/groupClassify/all",
        method: "get"
    });
}

//
export function groupClassifySave(data, formName, method = "post") {
    let url =
        formName === "add"
            ? "/admin/groupClassify/add"
            : "/admin/groupClassify/update";
    return axios({
        url: url,
        method: method,
        data: data
    });
}

//
export function groupClassifyDelete(data) {
    return axios({
        url: "/admin/groupClassify/delete",
        method: "post",
        data: data
    });
}
